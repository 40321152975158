import { Button, Col, Divider, Form, InputNumber, Modal, notification, Row, Spin, Typography } from "antd"
import { FormComponentProps } from 'antd/lib/form'
import { get } from 'lodash'
import * as React from 'react'
import { BIGDATA_CONSTANT } from '../../../../common/constant'
import { IActionUI, SHOW_COMMON_ERROR } from '../../../../redux/reducer/uiReducer'
import { useDispatch } from '../../../../redux/store'
import { CommonNotificationDeleteSuccessfully, CommonNotificationSaveError, CommonNotificationUpdateSuccessfully } from '../../../../shares/CommonNotification'
import { O2OFormItem } from '../../../../shares/O2OFormItem'
import { QuotaSettings, TypeQuotaByKeysAPIIngestion } from '../models'
import { SettingDAL } from '../SettingDAL'

interface Iprops extends FormComponentProps {
    commonActionFromDALV2: any
    id?: string
    selectedMenu: string
}
const Segment = Form.create<Iprops>({ name: "segment" })((props: Iprops) => {
    const [loading, setLoading] = React.useState(false)
    const [data, setData] = React.useState({} as TypeQuotaByKeysAPIIngestion)
    const [hasConfig, setHasConfig] = React.useState(false)

    const dispatch = useDispatch()

    const loadData = async () => {
        setLoading(true)
        const result: Promise<QuotaSettings> = await props.commonActionFromDALV2(SettingDAL.getQuotaSupperAdmin, props.id, "Segment")
        try {
            const response = await result
            if (response.success && response.result && response.result.quotaByKeys) {
                setData(response.result.quotaByKeys)
                setHasConfig(Boolean(response.result.id))
            } else {
                notification.error({
                    message: BIGDATA_CONSTANT.message.default.title.error,
                    description: get(response, "message", BIGDATA_CONSTANT.message.notification.fail)
                })
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    const handleUpdate = () => {
        props.form.validateFields(async (error: any, values: any) => {
            if (error) return

            try {
                setLoading(true)
                const payload = {
                    category: "Segment",
                    quotaByKeys: {
                        _filter_raw: values._filter_raw,
                        _filter_result: values._filter_result,
                        _final_result: values._final_result,
                    },
                    alertMessage: "Reached Max Allowed Records"
                }

                const result: Promise<any> = await props.commonActionFromDALV2(SettingDAL.updateQuotaLimit, props.id, "Segment", payload)

                const response = await result

                if (response.success) {
                    setLoading(false)
                    CommonNotificationUpdateSuccessfully()
                    setTimeout(() => {
                        loadData()
                    }, 500);
                } else {
                    setLoading(false)
                    CommonNotificationSaveError(get(response, "message", "Something went wrong!"))
                }
            } catch (error) {
                setLoading(false)
                dispatch({
                    type: SHOW_COMMON_ERROR,
                    commonError: error,
                } as IActionUI);
            }
        })
    }

    const handleRemove = async () => {
        try {
            const result: Promise<any> = await props.commonActionFromDALV2(SettingDAL.deleteQuotaConfig, props.id, "Segment")

            const response = await result

            if (response.success) {
                CommonNotificationDeleteSuccessfully()
                setTimeout(() => {
                    loadData()
                    props.form.resetFields()
                }, 500);
            } else {
                CommonNotificationSaveError(get(response, "message", "Something went wrong!"))
            }
        } catch (error) {
            dispatch({
                type: SHOW_COMMON_ERROR,
                commonError: error,
            } as IActionUI);
        }
    }

    React.useEffect(() => {
        if (props.selectedMenu === "menu2") {
            loadData()
        }
    }, [props.selectedMenu])
    return (
        <Spin spinning={loading}>
            <Form colon={true} hideRequiredMark={true}>
                <Row gutter={[10, 10]} type="flex" >
                    <Col xs={24}>
                        <Typography.Title level={4}> Segment Quota </Typography.Title>
                        <Divider type='horizontal' style={{ margin: 0 }} />
                    </Col>
                    <Col xs={24}>
                        <Typography.Title level={4} style={{ fontSize: 18, marginBottom: -10 }}> Filter </Typography.Title>
                    </Col>
                    <Col xs={12}>
                        <O2OFormItem help="" label="Max raw records">
                            {props.form.getFieldDecorator("_filter_raw", {
                                initialValue: get(data, "_filter_raw", 100),
                                rules: [{ required: true }],
                            })(
                                <InputNumber
                                    placeholder='Please enter number'
                                    min={100}
                                    max={50000000}
                                    formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    style={{ width: "100%" }}
                                />

                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={12}>
                        <O2OFormItem help="" label="Max result records">
                            {props.form.getFieldDecorator("_filter_result", {
                                initialValue: get(data, "_filter_result", 100),
                                rules: [{ required: true }],
                            })(
                                <InputNumber
                                    placeholder='Please enter number'
                                    min={100}
                                    max={5000000}
                                    formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    style={{ width: "100%" }}
                                />

                            )}
                        </O2OFormItem>
                    </Col>
                    <Col xs={24} style={{ marginTop: 10 }}>
                        <Typography.Title level={4} style={{ fontSize: 18, marginBottom: -10 }}> Segment </Typography.Title>
                    </Col>


                    <Col xs={12}>
                        <O2OFormItem help="" label="Max final records">
                            {props.form.getFieldDecorator("_final_result", {
                                initialValue: get(data, "_final_result", 100),
                                rules: [{ required: true }],
                            })(
                                <InputNumber
                                    placeholder='Please enter number'
                                    min={100}
                                    max={5000000}
                                    formatter={(value: string) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    style={{ width: "100%" }}
                                />

                            )}
                        </O2OFormItem>
                    </Col>

                    <Col xs={24}>
                        <Button loading={loading} type='primary' onClick={handleUpdate}>Update</Button>
                        {hasConfig && (
                            <Button type='default' onClick={() => {
                                Modal.confirm({
                                    title: BIGDATA_CONSTANT.message.default.titleConfirm,
                                    content: "Are you sure that you want to permanently remove this config?",
                                    okText: "Remove",
                                    cancelText: "Cancel",
                                    onOk: async () => {
                                        await handleRemove()
                                    },
                                })
                            }} style={{ marginLeft: 5 }}>Remove</Button>
                        )}

                    </Col>
                </Row>
            </Form>
        </Spin>

    )
})

export default Segment